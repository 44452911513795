import React from 'react'
import styled from 'styled-components'
import { initStore } from 'redux/store'
import withRedux from 'next-redux-wrapper'

import Wrapper from '../components/Wrapper'
import { HeaderRendering } from '../components/v2/Header'
import FooterIntercomClickableHome from '../components/shared/FooterStatic/FooterIntercomClickableHome'

import all from '../utils/allCss'
import { Text } from 'components/v2/Text'
import { Column, Row } from 'components/v2/Grid'
import { navHeight, getUrlFromReq } from '../utils/helpers'
import TalkToUs from 'components/Search/Results/talkToUs'
import { Button } from 'components/v2/Button'

const Page404 = ({ urlPath }) => {
  const metaTags = {
    title: 'Paciente Vittude - Encontre o Psicólogo ideal para você',
    description:
      'Encontre um psicólogo que combina com você!! Agende uma consulta com um psicólogo em seu bairro ou escolha um psicólogo online.',
    hreflang: urlPath
  }

  return (
    <Wrapper metaTags={metaTags} cssFile={all}>
      <HeaderRendering />
      <Container>
        <Content>
          <Text fontSize='64px' fontWeight='bold'>
            Ooooooops!
          </Text>
          <Column>
            <Text>Não encontramos a página que você estava procurando.</Text>
            <ErrorLink as='a' href='/' mt='36px' color='primary'>
              Voltar para a página inicial
            </ErrorLink>
            <ErrorLink as='a' href='http://meajuda.vittude.com' mt='12px' color='primary'>
              Preciso de ajuda
            </ErrorLink>
          </Column>
        </Content>
        <Row justifyContent='center'>
          <TalkToUs />
          <Button onClick={() => window.Intercom?.apply(null, 'show')}>[TESTE BTN]</Button>
        </Row>
      </Container>
      <FooterIntercomClickableHome />
    </Wrapper>
  )
}

Page404.getInitialProps = async ({ req, err, res }) => {
  return { urlPath: getUrlFromReq(req) }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  margin-top: ${navHeight};

  @media (max-width: 768px) {
    margin-top: 0;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 12rem;

  width: 100%;
  min-height: 700px;
  height: 100%;
  padding: 0 20px;
  margin: 0 auto;

  background-image: url('/static/images/404.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: 768px) {
    gap: 14rem;
  }

  @media (min-width: 1500px) and (max-width: 1920px) {
    gap: 24rem;
  }
`

const ErrorLink = styled(Text)`
  cursor: pointer;
  text-decoration: underline !important;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export default withRedux(initStore)(Page404)
