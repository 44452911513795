import React from 'react'

const TalkToUs = props => (
  <section id='more-psychos-2'>
    <div className='container'>
      <div className='row justify-content-center'>
        <p id='open_intercom'>
          Ainda não encontrou o seu psicólogo?
          <a onClick={() => window.Intercom.apply(null, 'show')}> Fale com a gente</a>
        </p>
      </div>
    </div>
  </section>
)

export default TalkToUs
